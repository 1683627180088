<script setup lang="ts">
import { NumberToString, USDollar, type ReconciliationField } from '@audit'
interface Props {
  value: ReconciliationField
}

const dayjs = useDayjs()

defineProps<Props>()
</script>

<template>
  <div
    class="flex h-full flex-col gap-1 rounded-md bg-opacity-25 p-4 text-xs text-white"
    :class="{
      'bg-primary':
        value?.delta &&
        (value?.dataType === 'currency' || value?.dataType === 'number') &&
        (value?.delta as number) > 0,
      'bg-red-500':
        value?.delta &&
        (value?.dataType === 'currency' || value?.dataType === 'number') &&
        (value?.delta as number) < 0,
      'bg-blue-500':
        value?.delta &&
        (value?.dataType === 'string' || value?.dataType === 'date'),
    }"
  >
    <template
      v-if="
        value?.delta &&
        (value?.dataType === 'currency' || value?.dataType === 'number')
      "
    >
      <div v-if="(value?.delta as number) > 0">
        {{
          `+${value?.dataType === 'currency' ? USDollar.format(value?.delta as number) : NumberToString.format(value.delta as number)}`
        }}
      </div>

      <div v-else-if="(value?.delta as number) < 0">
        {{
          `${value?.dataType === 'currency' ? USDollar.format(value?.delta as number) : NumberToString.format(value.delta as number)}`
        }}
      </div>
    </template>
    <div v-else-if="value?.delta && value?.dataType === 'string'">
      {{ value?.delta }}
    </div>
    <div v-else-if="value?.delta && value?.dataType === 'date'">
      {{ dayjs(value.delta).format('DD/MM/YYYY') }}
    </div>
    <template v-else>
      <span>-</span>
    </template>
  </div>
</template>
